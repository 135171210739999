import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MatNativeDateModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { PROXY_LOGIN_OPTIONS } from '@cuepid/admin/core/injection-tokens';
import { environment } from '@cuepid/admin/env';
import {
  CuepidCoreModule,
  ErrorToastInterceptor,
  GoogleMapService,
} from '@cuepid/core';
import { EgretModule, LAYOUT_CONFIG, NavigationService } from '@cuepid/egret';
import { CuepidDateAdapter } from '@cuepid/enterprise/core/adapters/cuepid-date.adapter';
import * as Sentry from '@sentry/angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { rootRouterConfig } from './app.routing';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { AdminNavigationService } from './core/services/admin-navigation.service';
import { IndexComponent } from './pages/index/index.component';
import { SharedComponentsModule } from './shared/components/shared-components.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const layoutConfig = {
  matTheme: 'egret-light-purple',
  perfectScrollbar: true,
  isMobile: false,
  dir: 'ltr',
  navigationPos: 'side',
  sidebarCompactToggle: false,
  sidebarStyle: 'full',
  sidebarColor: 'black',
  topbarFixed: false,
  topbarColor: 'black',
  footerFixed: false,
  footerColor: 'slate',
  useBreadcrumb: false,
  breadcrumb: 'simple',
};

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ToastrModule.forRoot(),
    FlexLayoutModule,
    NgxMaterialTimepickerModule.setLocale('ja-JP'),
    EgretModule,
    CuepidCoreModule.forRoot({
      apiBaseUrl: environment.apiURL,
      googleMapApiKey: environment.googleMapApiKey,
    }),
    SharedComponentsModule,
    MatButtonModule,
    ReactiveFormsModule,
    RouterModule,
    MatNativeDateModule,
    MatDialogModule,
  ],
  declarations: [AppComponent, IndexComponent],
  providers: [
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler() },
    { provide: Sentry.TraceService, deps: [Router], useValue: undefined },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorToastInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: NavigationService, useClass: AdminNavigationService },
    { provide: LAYOUT_CONFIG, useValue: layoutConfig },
    { provide: DateAdapter, useClass: CuepidDateAdapter },
    { provide: PROXY_LOGIN_OPTIONS, useValue: environment.proxyLogin },
    GoogleMapService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
