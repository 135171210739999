import { Component, OnInit } from '@angular/core';
import { egretAnimations } from '@cuepid/egret';
import { AuthService } from '@cuepid/admin/core/services/auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  animations: egretAnimations,
})
export class IndexComponent implements OnInit {

  constructor(private authService: AuthService) {
  }

  ngOnInit() {
  }

  signIn() {
    this.authService.signIn();
  }
}
