import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  API_BASE_URL,
  Company,
  ContractPlan,
  Paginator,
  UseApplication,
} from '@cuepid/core';
import { NavigationService } from '@cuepid/egret';
import { plainToClass } from 'class-transformer';
import { format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private readonly http: HttpClient,
    private navService: NavigationService,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string
  ) {}

  getApplicationList(
    page: number,
    perPage: number
  ): Observable<Paginator<UseApplication>> {
    return this.http.get<Paginator<Company>>(
      `${this.apiBaseUrl}/company/application`,
      {
        params: {
          page: `${page}`,
          per_page: `${perPage}`,
        },
      }
    );
  }

  updateApplicationListCount(): void {
    this.http.get(`${this.apiBaseUrl}/company/application/count`).subscribe(res => {
      if(typeof res === 'number') {
        this.navService.updateApplicationListCount(res);
      }
    });
  }

  activate(company: UseApplication): Observable<Company> {
    return this.http.post<Company>(
      `${this.apiBaseUrl}/company/application/${company.id}/activate`,
      {}
    );
  }

  all(
    page: number,
    perPage: number,
    keyword: string = ''
  ): Observable<Paginator<Company>> {
    const params: any = {
      page: `${page}`,
      per_page: `${perPage}`,
    };

    if (!!keyword) {
      // +等をそのままサーバーに送るため先行してエンコードする
      params.keyword = encodeURIComponent(keyword);
    }

    return this.http.get<Paginator<Company>>(`${this.apiBaseUrl}/company`, {
      params,
    });
  }

  get(id: number): Observable<Company> {
    return this.http
      .get<Company>(`${this.apiBaseUrl}/company/${id}`)
      .pipe(map((data) => plainToClass(Company, data)));
  }

  addContract(id: number, plan: ContractPlan): Observable<ContractPlan> {
    return this.http.post<ContractPlan>(
      `${this.apiBaseUrl}/company/${id}/contract`,
      plan
    );
  }

  updateContract(
    id: number,
    targetDate: Date,
    plan: ContractPlan
  ): Observable<ContractPlan> {
    const targetYearMonth = format(targetDate, 'yyyyMM');
    return this.http.put<ContractPlan>(
      `${this.apiBaseUrl}/company/${id}/contract/${targetYearMonth}`,
      plan
    );
  }

  proxyLogin(id: number): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}/company/${id}/proxy-login`);
  }
}
