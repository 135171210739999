import { Integrations } from '@sentry/tracing';
import { routingInstrumentation } from '@sentry/angular';

export const environment = {
  production: true,
  apiURL: 'https://admin.cuepid.net/api/administrator',
  googleMapApiKey: 'AIzaSyB5jjPFip-qt3xdKrRyJzdz2JX4TjJS-Tk',

  sentry: {
    enabled: true,
    debug: false,
    environment: 'production',
    dsn: 'https://cc6a699e26d34ed3859a3041f4be4ca9@o206970.ingest.sentry.io/5476258',
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['https://admin.cuepid.net'],
        routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.2,
  },

  proxyLogin: {
    enterpriseBaseUrl: 'https://enterprise.cuepid.net',
  },
};
