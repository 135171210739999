import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL, WINDOW } from '@cuepid/core';
import { NavigationService } from '@cuepid/egret';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    @Inject(WINDOW) private readonly window: Window, @Inject(API_BASE_URL) private readonly baseUrl: string,
    private readonly http: HttpClient, private navService: NavigationService
  ) {
  }

  export(id: number): void {
    this.window.open(`${this.baseUrl}/campaign/${id}/export`);
  }

  updateUnapprovedCampaignCount(): void {
    this.http.get(`${this.baseUrl}/campaign/count`).subscribe(res => {
      if(typeof res === 'number') {
        this.navService.updateUnapprovedCampaignCount(res);
      }
    });
  }
}
