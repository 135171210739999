import { Injectable } from '@angular/core';
import { IMenuItem, NavigationService } from '@cuepid/egret';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdminNavigationService extends NavigationService {
  private applicationListCount = new BehaviorSubject<number>(0);
  private unapprovedCampaignCount = new BehaviorSubject<number>(0);
  private readonly MENUS: IMenuItem[] = [
    {
      type: 'separator',
      name: 'メインメニュー'
    },
    {
      name: 'ダッシュボード',
      type: 'link',
      icon: 'dashboard',
      state: 'dashboard',
    },
    {
      name: '審査依頼一覧',
      type: 'link',
      icon: 'list',
      state: 'campaign/review',
      count: this.unapprovedCampaignCount
    },
    {
      name: '企業メニュー',
      type: 'dropDown',
      icon: 'business',
      hasEvents: true,
      events: ['update-application-list-count'],
      sub: [
        {
          name: '企業一覧',
          type: 'link',
          state: 'company/list',
        },
        {
          name: '利用申込一覧',
          type: 'link',
          state: 'company/applications',
          count: this.applicationListCount
        },
      ],
    },
  ];


  get iconTypeMenuTitle(): string {
    return 'Frequency Menu';
  }

  get menuItems() {
    return new BehaviorSubject(this.MENUS);
  }

  get menuItems$() {
    return this.menuItems.asObservable();
  }

  updateApplicationListCount(newCount: number): void {
    this.applicationListCount.next(newCount);
  }
  
  updateUnapprovedCampaignCount(newCount: number): void {
    this.unapprovedCampaignCount.next(newCount);
  }

}
